import type { Splide } from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINTS } from '@/js/utils/breakpoints';

const splides: Splide[] = [];

const getElements = () => ({
  imageSliderElements: document.querySelectorAll<HTMLElement>(
    '.content-block--image-slider.splide',
  ),
});

export default defineModule(
  async () => {
    const { imageSliderElements } = getElements();
    if (!imageSliderElements.length) return;

    const [{ Splide }, { AutoScroll }] = await Promise.all([
      import('@splidejs/splide'),
      import('@splidejs/splide-extension-auto-scroll'),
    ]);

    imageSliderElements.forEach((imageSliderElement) => {
      splides.push(
        new Splide(imageSliderElement, {
          type: 'loop',
          focus: 'center',
          drag: false,
          pagination: false,
          autoScroll: {
            speed: 0.75,
            pauseOnHover: false,
            pauseOnFocus: false,
          },
          arrows: false,
          gap: 0,
          fixedHeight: '75vw',
          autoWidth: true,
          mediaQuery: 'min',
          breakpoints: {
            [BREAKPOINTS.lg]: {
              gap: '5rem',
              fixedHeight: '33.5rem',
            },
            [BREAKPOINTS['16in']]: {
              gap: '6rem',
              fixedHeight: '38rem',
            },
          },
        }).mount({
          AutoScroll,
        }),
      );
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy();
    }
  },
);
