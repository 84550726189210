import type { EmblaCarouselType } from 'embla-carousel';
import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINTS } from '@/js/utils/breakpoints';

const emblaApis: EmblaCarouselType[] = [];

const getElements = () => ({
  emblaElements: document.querySelectorAll<HTMLElement>(
    '.content-block--products-slider .embla',
  ),
  slideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--products-slider .embla .embla__slide',
  ),
});

const togglePrevNextButtonsState = (emblaApi: EmblaCarouselType) => {
  if (!emblaApi) return;

  const emblaElement = emblaApi.rootNode().closest<HTMLElement>('.embla');
  if (!emblaElement) return;

  const emblaPrevButtonElement = emblaElement?.querySelector<HTMLElement>(
    '.embla__buttons .embla__button--prev',
  );
  const emblaNextButtonElement = emblaElement?.querySelector<HTMLElement>(
    '.embla__buttons .embla__button--next',
  );

  if (emblaPrevButtonElement) {
    if (emblaApi.canScrollPrev()) {
      emblaPrevButtonElement.removeAttribute('disabled');
    } else {
      emblaPrevButtonElement.setAttribute('disabled', 'disabled');
    }
  }

  if (emblaNextButtonElement) {
    if (emblaApi.canScrollNext()) {
      emblaNextButtonElement.removeAttribute('disabled');
    } else {
      emblaNextButtonElement.setAttribute('disabled', 'disabled');
    }
  }
};

export default defineModule(
  async () => {
    const { emblaElements } = getElements();
    if (!emblaElements.length) return;

    const [{ default: EmblaCarousel }, { default: ClassNames }] =
      await Promise.all([
        import('embla-carousel'),
        import('embla-carousel-class-names'),
      ]);

    emblaElements.forEach((emblaElement) => {
      const emblaViewportElement =
        emblaElement.querySelector<HTMLElement>('.embla__viewport');
      if (!emblaViewportElement) return;

      const emblaApi = EmblaCarousel(
        emblaViewportElement,
        {
          containScroll: 'trimSnaps',
          align: 'start',
          inViewThreshold: 0.5,

          breakpoints: {
            [`(width < ${BREAKPOINTS.md}px)`]: {
              active: false,
            },
          },
        },
        [ClassNames()],
      );

      const onPrevButtonClick = () => {
        if (!emblaApi.canScrollPrev()) return;
        emblaApi.scrollPrev();
      };
      const emblaPrevButtonElement = emblaElement.querySelector<HTMLElement>(
        '.embla__buttons .embla__button--prev',
      );
      emblaPrevButtonElement?.addEventListener('click', onPrevButtonClick);

      const onNextButtonClick = () => {
        if (!emblaApi.canScrollNext()) return;
        emblaApi.scrollNext();
      };
      const emblaNextButtonElement = emblaElement.querySelector<HTMLElement>(
        '.embla__buttons .embla__button--next',
      );
      emblaNextButtonElement?.addEventListener('click', onNextButtonClick);

      emblaApi
        .on('init', togglePrevNextButtonsState)
        .on('select', togglePrevNextButtonsState)
        .on('reInit', togglePrevNextButtonsState)
        .on('resize', togglePrevNextButtonsState)
        .on('destroy', () => {
          emblaPrevButtonElement?.removeEventListener(
            'click',
            onPrevButtonClick,
          );
          emblaNextButtonElement?.removeEventListener(
            'click',
            onNextButtonClick,
          );
        });

      emblaApis.push(emblaApi);
    });
  },
  () => {
    while (emblaApis.length > 0) {
      const emblaApi = emblaApis.pop();
      if (!emblaApi) return;

      emblaApi
        .off('select', togglePrevNextButtonsState)
        .off('init', togglePrevNextButtonsState)
        .off('reInit', togglePrevNextButtonsState)
        .off('resize', togglePrevNextButtonsState);

      emblaApi.destroy();
    }
  },
);
